import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'
import UtilsService from '../services/utils.service'

export default class BannerComponent {
    constructor() {
        this.bannerSlider()
    }

    async bannerSlider() {
        const bannerSliderSelector = '.js-header-banner-slider'
        const navigationSliderSelector = '.js-header-slider-next-slide'
        const paginationSliderSelector = '.js-header-slider-pagination'
        const options = {
            loop: true,
            parallax: true,
            speed: 1200,
            preloadImages: false,
            grabCursor: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            lazy: {
                enabled: true,
                loadPrevNext: true,
            },
            navigation: {
                nextEl: navigationSliderSelector,
            },
            pagination: {
                el: paginationSliderSelector,
                type: 'bullets',
                clickable: true,
            },
        }

        if ($(bannerSliderSelector).length) {
            UtilsService.swiperWrapper(bannerSliderSelector)
        }

        await Swiper.create(bannerSliderSelector, options)
    }
}
