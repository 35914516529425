export default class Utils {
    static swiperWrapper(selector, childElements = '*', classesModifier = '') {
        $(`${selector} > ${childElements}`)
            .wrapAll('<div class="swiper-wrapper"></div>')
            .addClass(`swiper-slide ${classesModifier}`)
    }

    static toggleIsActiveClass(selector, event = 'click') {
        $(selector).on(event, function () {
            $(`${selector}.is-active`).removeClass('is-active')
            $(this).addClass('is-active')
        })
    }

    static isAnimable() {
        return (
            !$('body.eco-mode-active').length && window.matchMedia('(min-width: 1000.1px)').matches
        )
    }
}
