import Utils from '../services/utils.service'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.showSubmenu()
        NavbarComponent.closeMenu()
        NavbarComponent.langSwitcher()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 150
            const offsetOnScroll = 100
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll) {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '.js-menu-item'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if ($('.navbar--ontop').length && $('body.has-banner').length) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }

            //Close ecobar when menu is open
            if (body.hasClass('menu-open') && $('.navbar--ontop').length) {
                $('body').removeClass('eco-bar-open')
                $('.eco-bar').addClass('eco-bar--hidden')
            }
        })

        //Open/Close submenu
        if (window.matchMedia('(max-width: 1000px)').matches) {
            $(prev).on('click', (e) => {
                $('body').removeClass('body-submenu-open')
                $(more).removeClass('submenu-open')
            })
        }
    }

    static openSubmenuMobile() {
        const $menuItemLinks = $('.js-menu-item')
        $menuItemLinks.on('click', function () {
            $('body').addClass('body-submenu-open')
            $(this).addClass('has-submenu-open')
        })
    }

    static showSubmenu() {
        if (window.matchMedia('(min-width: 1000.1px)').matches) {
            $('.js-menu-item .menu__item__link').on('click', function () {
                // second click (already open)
                if ($(this).parent('.menu__wrapper .menu__item').hasClass('is-active')) {
                    return true
                }

                // first click (open submenu)
                $('.menu__wrapper .menu__item')
                    .removeClass('is-active')
                    .removeClass('has-submenu-open')
                $('.menu__wrapper .menu__item__submenu').removeClass('submenu-open')
                $(this).parent('.menu__wrapper .menu__item').addClass('is-active')

                return false
            })
        } else {
            $('.js-menu-item .menu__item__link').on('click', function () {
                // second click (already open)
                if ($(this).parent('.menu__wrapper .menu__item').hasClass('has-submenu')) {
                    $('body').addClass('body-submenu-open')
                    $(this).parent('.menu__wrapper .menu__item').addClass('submenu-open')
                    return false
                }
            })
        }
    }

    static closeMenu() {
        $('.js-close-menu').on('click', () => {
            const $body = $('body')
            $body.removeClass('menu-open')
            $body.removeClass('body-submenu-open')
            if (window.matchMedia('(min-width:1000.1px)').matches) {
                $('.menu__wrapper .menu__item__submenu').removeClass('submenu-open')
            } else {
                $('.menu__wrapper .menu__item').removeClass('submenu-open')
            }
        })
    }

    static langSwitcher() {
        const $langSwitcher = $('.js-lang-switcher')
        const $langList = $('.js-lang-list')

        if ($langSwitcher.length > 0) {
            $langSwitcher.on('click', () => {
                $langList.toggleClass('is-visible')
            })
        }
    }
}
